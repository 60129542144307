import * as React from "react";
import { graphql } from "gatsby";
import { Layout, SEO, CJumbotron, CBreadCrumb, LWrap, CRestaurantCard, CSectTitle, CLinkCard, LPickup, CPostCard, CFixBtnList02} from "../../components/_index"
import pickupChoice from "../../utils/pickup-choice"
import pickupGet from "../../utils/pickup-get"
import menuChoice from "../../utils/menu-choice"
import menuGet from "../../utils/menu-get"

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter
  const moreData = pickupChoice(pickupGet(), ['MORE'], 0)

  return (
    <Layout>
      <SEO title={frontmatter?.title}
        description={frontmatter?.description}
      />
      <CJumbotron
        size="large"
        data={{
          title: {
            en: 'restaurant',
            ja: 'レストラン'
          },
          imgList: [
            {
              img: {
                src: '/assets/images/restaurants/kv.png'
              },
              imgSp: {
                src: '/assets/images/restaurants/kv__sp.png'
              }
            },
          ]
        }}
      >
        <CBreadCrumb data={{
          parent: [
          ],
          current: {
            label: 'レストラン'
          }
        }} />
      </CJumbotron>
      <section className="l_sect">
        <LWrap>
          <h2 className="c_headingLv2">想い出になる<br />仙台リゾートの味と時間</h2>
          <p className="c_sectLead">気軽にシェフの珠玉の料理を味わえるリゾートレストラン。<br />四季折々の美しい景色の中で旬の味覚を贅沢に存分におたのしみいただけます。</p>
          <CRestaurantCard data={[
            {
              link: {
                href: "/restaurants/chefsterrace/"
              },
              img: {
                src: "/assets/images/restaurants/img_restaurant.png",
                alt: "シェフズ テラス"
              },
              logo: {
                src: "/assets/images/restaurants/img_restaurant_logo.svg",
                alt: "シェフズ テラス"
              },
              subTitle: <>レストラン</>,
              title: <>シェフズ テラス</>,
              text: <>
                ガーデンを眺めながら食をたのしむ<br />緑が心地よいオールデイダイニング
              </>
            },
            {
              link: {
                href: "/restaurants/nanatsumori/"
              },
              img: {
                src: "/assets/images/restaurants/img_restaurant02.png",
                alt: "七つ森"
              },
              logo: {
                src: "/assets/images/restaurants/img_restaurant02_logo.svg",
                alt: "七つ森"
              },
              subTitle: <>レストラン</>,
              title: <>七つ森</>,
              text: <>
                食材の味をそのままにたのしむ<br />
                匠の技を感じるこだわりの鉄板焼
              </>
            },
            {
              link: {
                href: "/restaurants/keikaen/"
              },
              img: {
                src: "/assets/images/restaurants/img_restaurant03.png",
                alt: "桂花苑"
              },
              logo: {
                src: "/assets/images/restaurants/img_restaurant03_logo.svg",
                alt: "桂花苑"
              },
              subTitle: <>レストラン</>,
              title: <>桂花苑</>,
              text: <>
                エキサイティングな食体験をたのしむ<br />
                空間と匠の技術が織り成す中国料理
              </>
            },
            {
              link: {
                href: "/restaurants/fontaine/"
              },
              img: {
                src: "/assets/images/restaurants/img_restaurant04.png",
                alt: "フォンテーヌ"
              },
              logo: {
                src: "/assets/images/restaurants/img_restaurant04_logo.svg",
                alt: "フォンテーヌ"
              },
              subTitle: <>ラウンジ</>,
              title: <>フォンテーヌ</>,
              text: <>
                リゾートステイを優雅にたのしむ<br />
                癒やされるロビーラウンジ
              </>
            },
          ]} />
        </LWrap>
      </section>
      <LPickup />
    </Layout>
  )
}

export default SubPage;

export const query = graphql`
  query ($slug: String!){
    markdownRemark(fields: {slug: {eq: $slug}}) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`